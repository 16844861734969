import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, Link, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Reviews from "../../components/landingPage/Reviews";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";
import Videodermoscopy from "../../components/landingPage/Videodermoscopy";
import Surgery from "../../components/landingPage/Surgery";
import Melanoma from "../../components/landingPage/Melanoma";
import Abcde from "../../components/landingPage/Abcde";
import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import Faq from "../../components/landingPage/Faq";
import RiskGroup from "../../components/landingPage/RiskGroup";
import Covid from "../../components/landingPage/Covid";
import ImportantInfo from "../../components/landingPage/ImportantInfo";
import Contact from "../../components/landingPage/Contact";
import ProcedureDetails from "../../components/landingPage/ProcedureDetails";
import WhyUs from "../../components/landingPage/WhyUs";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import Button from "../../components/Button";
import ButtonDrw from "../../components/landingPage/ButtonDrw";
import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_DERMATOLOGIA,
  GTM_E_ONKOLOGIA,
  GTM_NIECHIRURGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";
import Surgery2 from "../../components/landingPage/Surgery2";
import Laser from "../../components/landingPage/Laser";

const GTM_LOCATION = "szczecin-laserowe";

const REVIEWS = [
  {
    author: "Kasia M.",
    city: "Szczecin",
    review:
      "Doskonała obsługa! Profesjonalna i troskliwa Pani doktor wyjaśniła mi wszystko odnośnie laserowego usuwania włókniaków. Procedura była bezbolesna, a efekty są świetne! Polecam z całego serca.",
  },
  {
    author: "Monika S.",
    city: "Szczecin",
    review:
      "Jesteśmy bardzo zadowoleni z naszych wizyt w ośrodku Twoje Znamiona. Ja miałam laserowe usuwanie brodawek łojotokowych, a mój partner włókniaka. Wszystko przebiegło sprawnie i profesjonalnie.",
  },
  {
    author: "Michał P.",
    city: "Szczecin",
    review:
      "Skorzystałem z laserowego usuwania kurzajki w tym ośrodku i jestem bardzo zadowolony z rezultatów. Procedura była szybka, skuteczna i bezbolesna. Co więcej, skóra szybko się zagoiła, a kurzajka nie powróciła. Bardzo polecam ten ośrodek wszystkim poszukującym profesjonalnej opieki.",
  },
];


const PRICES = [
  {
    isOpened: true,
    categoryName: "Laserowe usuwanie zmian skórnych",
    categorySub: "m.in brodawki, włókniaki, kurzajki",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
        data-apptnow-widget="673eb3d0-be5a-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "380",
        label: "usunięcie 1 zmiany skórnej do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "70",
        label:
          "*usunięcie dodatkowej zmiany skórnej w trakcie tej samej wizyty do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "430",
        label:
          "usunięcie 1 zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "430",
        label:
          "usunięcie 1 brodawki wirusowej - kurzajki",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej brodawki wirusowej - kurzajki w trakcie tej samej wizyty",
          sub: "w przypadku rozległych zmian o liczbie zabiegów decyduje lekarz",
      },
      {
        price: "200",
        label: "konsultacja lekarska",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Badanie znamion - wideodermatoskopia",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_WIDEO}`}
        data-apptnow-widget="dd0df680-be59-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "450",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła ",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "350",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "250",
        label:
          "wideodermatoskopia - wizyta kontrolna do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_CHIRURGIA}`}
        data-apptnow-widget="24ff2b30-be5a-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "zabiegi chirurgiczne są wykonywane u pacjentów pełnoletnich",
      },
      {
        price: "550",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "330",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "650",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "380",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "0",
        label: "wizyta kontrolna po zabiegu ze zdjęciem szwów",
        sub: "*dla pacjentów, którzy odbyli zabieg w Twoje Znamiona",
      },
      {
        price: "100",
        label: "zdjęcie szwów",
        sub: "*dla pacjentów, którzy wykonali zabieg poza Twoje Znamiona",
      },
    ],
  },
  
  {
    isOpened: false,
    categoryName: "Konsultacje onkologiczne - czerniak",
    items: [
      {
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_ONKOLOGIA}`}
            data-apptnow-widget="3c785070-0c57-11ee-b8f3-a124fcec387c"
          />
        ),
        price: "250",
        label: "konsultacja onkologiczna - nowotwory skóry",
        sub:
          "ponowna konsultacja onkologiczna (w przeciągu 6 miesięcy): 180 zł",
      },
      {
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
            data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
          />
        ),
        price: "200",
        label: "e-wizyta - wideokonferencja",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje dermatologiczne",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_DERMATOLOGIA}`}
        data-apptnow-widget="ae3b4f50-be5a-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "250",
        label: "konsultacja dermatologiczna",
        sub: "inne schorzenia skóry: trądzik, łuszczyca, grzybica itp.",
      },
    ],
  },
];

const OFFERS = [
  {
    type: "niechirurgiczne",
    title: "Zabiegi laserowe",
    desc:
      "Nieinwazyjne zabiegi laserowe usuwania zmian skórnych pomogą Ci pozbyć się zmian takich jak włókniaki, brodawki czy kurzajki. Laserowe usuwanie pieprzyków jest zarezerwowane dla zmian łagodnych, a kwalifikację do zabiegu wykonuje lekarz.",
      link:"#cennik",
  },
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Każda niepokojąca zmiana skórna powinna być usunięta chirurgicznie. Zabieg polega na wycięciu zmiany wraz z fragmentem zdrowej skóry. W każdym przypadku wykonywane jest badania histopatologiczne, celem postawienia jednoznacznej diagnozy. ",
      link:"/szczecin/usuwanie-znamion-pieprzykow",
  },
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "Wideodermatoskopowe badanie wszystkich znamion z mapowaniem zmian wytypowanych przez lekarza do obserwacji i usunięcia pomoże Ci zachować zdrowie, a powiększony dermatoskopowy obraz pomoże lekarzowi w wyborze odpowiedniej metody usunięcia Twoich zmian skórnych.",
      link:"/szczecin/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  },
];

const INFOS = [
  {
    title: "Ile trwa zabieg?",
    desc:
      "Zabieg laserowego usuwania zmian skórnych jest krótki, nie wymaga pobytu w szpitalu, trwa zwykle ok. 10 - 20 min.",
  },
  {
    title: "Jakie zmiany możemy usuwać laserowo?",
    desc:
      "Metoda laserowego usuwania znamion jest zarezerwowana dla zmian łagodnych takich m.in jak brodawki, włókniaki, kurzajki, kłykciny czy kępki żółte.",
  },
  {
    title: "Czy zabieg laserowy boli?",
    desc:
      "Zazwyczaj jest to zabieg niebolesny, może powodować uczucie lekkiego pieczenia lub mrowienia, możliwe jest też zastosowanie znieczulenia miejscowego.",
  },
];

const CtaUmowSie = () => {
  return (
    <AnchorLink href="#cennik" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </AnchorLink>
  );
};
const CtaCennik = () => {
  return (
    <AnchorLink href="#cennik" title="Cennik">
      Ile to kosztuje?
    </AnchorLink>
  );
};

const SzczecinBadanieZnamionPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title:
        "Czy laserowe usuwanie znamion zostawi blizny?",
      text:
        "Zastosowanie lasera CO2 minimalizuje ryzyko powstawania blizn, dzięki precyzyjnemu i kontrolowanemu procesowi leczenia. Po zabiegu zazwyczaj pozostają niewielkie zaczerwienienia, które stopniowo zanikają.",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Czy muszę powtarzać zabieg, aby uzyskać trwałe efekty?",
      text:
        "W większości przypadków jednorazowe usuwanie jest skuteczne, jednak w zależności od rodzaju zmiany i indywidualnych czynników może być konieczne powtórzenie zabiegu. Lekarz oceni to podczas konsultacji.",
    },
  ];

  return (
    <Layout bg_color="purple" constactCtaComponent={<CtaUmowSie />} location={location}>
      <SEO
        title="Laserowe Usuwanie Zmian Skórnych w Szczecinie - twojeznamiona.pl"
        description="Laserowe Usuwanie Zmian Skórnych w Szczecinie - Od precyzyjnej diagnostyki po bezpieczne i nieinwazyjne usuwanie znamion, pieprzyków, brodawek, włókniaków, kurzajek i innych łagodnych zmian skórnych. Zaufaj specjalistom w dziedzinie diagnostyki zmian skórnych."
        keywords="usuwanie laserowe, nieinwazyjne, usuwanie kurzajek, brodawek, włókniaków, znamion, pieprzykow, piegów, pypciów, usuwanie brodawek z twarzy, wymrażanie kurzajek, wymrazanie brodawek"
      />
      <Hero
        h1="Laserowe Usuwanie Zmian Skórnych w Szczecinie"
        oneLiner="Od precyzyjnej diagnostyki po bezpieczne i nieinwazyjne usuwanie znamion, pieprzyków, brodawek, włókniaków, kurzajek i innych łagodnych zmian skórnych."
        oneLinerMore="Zaufaj specjalistom w dziedzinie diagnostyki zmian skórnych.Wszystkie swoje działania skupiamy na kompleksowej diagnostyce znamion, aby wyeliminować nowotwory skóry z życia milionów ludzi."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaCennik />}
        type="laser"
      />
      <Offer className="home_offer" data={OFFERS} />
      <ImportantInfo
        h2="Laserowe Usuwanie Zmian Skórnych"
        className="home_important_info"
        data={INFOS}
      />

      <PriceList className="home_price_list" data={PRICES} ifProgramProfilaktykiAvailable/>

      <Contact className="home_contact" where="w Szczecinie" city="Szczecin" />
      <Location
        className="home_location"
        coords={{
          lat: 53.427594092878905,
          lng: 14.53215226497938,
        }}
        googleMarker={{
          data: ["ul. Pocztowa 41a/29", "70-356 Szczecin"],
          link: "https://g.page/twoje-znamiona-szczecin?share",
        }}
        additionalDetails="Ośrodek znajduje się przy ul. Pocztowej 41a/29 w bliskiej okolicy Wydziału Nauk Ścisłych i Przyrodniczych Uniwersytetu Szczecińskiego. Wejście do ośrodka w kamienicy, naprzeciw sklep Żabka."
        locationInfo={["Centrum Medyczne Medify.me", "ul. Pocztowa 41a/29", "70-356 Szczecin"]}
        hoursInfo={["pon. - pt.: 09:00⁃20:00"]}
        transportationInfo={[
          "Najbliższe przystanki:",
          "Felczaka, Bohaterów Warszawy, Wawrzyniaka",
        ]}
      />
      <Gallery
        className="home_gallery"
        data={graph.gallery}
        city="szczecin"
        imageTitlePostfix="w Szczecinie"
      />
      <Laser
        className="home_two_col_section_left_img with_no_padding_top"
        reverse={false}
      />

      <Reviews className="home_reviews" data={REVIEWS} />
      <Faq className="home_faq with_padding_top_bottom" data={FAQS} />
      <Surgery className="home_two_col_section_left_img" reverse={true} />
      <Melanoma className="home_melanoma" />
      <Dermoscopy
        className="home_two_col_section_right_img with_no_padding_top"
        reverse={true}
      />

      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta isLanding />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: { relativePath: { regex: "/szczecin1.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: { relativePath: { regex: "/szczecin2.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery3: allFile(
          filter: { relativePath: { regex: "/szczecin3.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq1.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq2.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-1-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-2-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <SzczecinBadanieZnamionPage
        graph={{
          gallery: [
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
            // data.ImageGallery3.nodes[0],
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
